import desktopLogo from '../media/desktopLogo.png'
import mobileLogo from '../media/mobileLogo.png'

export default function NavBar() {
  return (
    <div as="nav" style={styles.bgColor}>
        <div className="max-w-7xl px-2 sm:px-6 lg:px-8 lg:pt-8 2xl:mx-auto py-4 mx-auto">
            <div className="relative flex items-center justify-between xs:h-14 lg:h-14">
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                        <img
                            className="block lg:hidden h-8 w-auto pt-4"
                            src={mobileLogo}
                            style={styles.mobile}
                            alt="Workflow"
                        />
                        <img
                            className="hidden lg:block h-8 w-auto"
                            src={desktopLogo}
                            style={styles.desktop}
                            alt="epicquiz"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const styles = {
    bgColor: {
        backgroundColor: '#171031'
    },
    desktop: {
        width: '150px',
        height: '50px',
    },
    mobile: {
        width: '60px',
        height: '70px',
    }
}