export default function DeleteAccount() {
    return (
        <>
            <h2 class="text-2xl font-semibold mb-4">Suppression de Votre Compte</h2>

            <p class="mb-4">La suppression d'un compte entraîne la suppression définitive de l'ensemble des données associées à ce compte que nous stockons, garantissant ainsi la protection de votre vie privée.</p>

            <p class="mb-4">Pour supprimer votre compte en toute simplicité, suivez ces étapes :</p>
            <ol class="list-decimal pl-6 mb-4">
                <li>Connectez-vous à votre compte en utilisant vos identifiants.</li>
                <li>Accédez au <b>Panneau de Configuration</b> de votre compte.</li>
                <li>À partir du Panneau de Configuration, cliquez sur l'option <b>Supprimer Mon Compte</b>.</li>
                <li>Une fois que vous avez confirmé cette action, votre compte sera clôturé et toutes vos données seront supprimées de manière irréversible.</li>
            </ol>

            <p class="mb-4">Nous comprenons que les circonstances peuvent varier, et si à un moment donné vous décidez de mettre fin à votre expérience avec nous, nous vous offrons un processus simple et sécurisé pour supprimer votre compte. Votre satisfaction est notre priorité.</p>
        </>
    )
}