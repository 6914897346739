import React from 'react';
import {MobileView, isMobile, isAndroid, isIOS} from 'react-device-detect';

export default function DownloadPopup() {
    const [isOpen, setOpen] = React.useState(false);

    const androidLink = "https://play.google.com/store/apps/details?id=com.halittar.epicquiz"
    const iosLink = 'https://apps.apple.com/ci/app/epic-quiz/id6476051075?platform=iphone'

    function getLink () {
        if(isAndroid) return androidLink;
        if(isIOS) return iosLink
        return null
    }

    React.useEffect(() => {
        setOpen(true)
    }, [])

    if(!isMobile) return null
    return (
        <MobileView>
            {
                !isOpen ? null :
                <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-2 text-center sm:block sm:p-0">

                        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                {
                                    isAndroid && <img id="android-icon" class="mx-auto h-12 w-12 my-5" src="https://i.pinimg.com/originals/53/6f/29/536f2903b5a68939cd5a997c2231f8bf.png" alt="playstore" />
                                }
                                {
                                    isIOS && <img id="ios-icon" class="mx-auto h-12 w-12 my-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/App_Store_%28OS_X%29.svg/1200px-App_Store_%28OS_X%29.svg.png" alt="appstore" />
                                }
                                <div class="mt-3 text-center sm:mt-5">
                                    <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                                        Télécharger l'application mobile
                                    </h3>
                                    <div class="mt-2">
                                        <p class="text-sm font-medium text-gray-500">
                                            Notre application mobile est disponible sur votre store ! Téléchargez la dès maintenant.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button onClick={() => {window.open(`${getLink()}`, '_blank')}} type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-bold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                                    Télécharger l'application
                                </button>
                                <button onClick={() => {setOpen(false)}} type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                                    Continuer sur le site
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </MobileView>
    )
}