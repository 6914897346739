export default function DataPolicy() {
    return (
        <>
            <h2 class="text-2xl font-semibold mb-4">Politique de Confidentialité et Gestion des Données</h2>
            
            <p class="mb-4">Merci d'utiliser notre application. Votre vie privée et la sécurité de vos données sont essentielles pour nous. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et comment nous les protégeons. En utilisant notre application, vous acceptez cette politique de confidentialité.</p>
            
            <h3 class="text-lg font-semibold mb-2">Informations Collectées</h3>
            <ul class="list-disc pl-6 mb-4">
                <li>Adresse e-mail</li>
                {/* <li>Nom et Prénom</li> */}
                <li>Numéro de téléphone</li>
                <li>Photo (optionnelle)</li>
            </ul>
            
            <h3 class="text-lg font-semibold mb-2">Utilisation des Données</h3>
            <p class="mb-4">Dans le cadre de l'utilisation de l'application, nous utilisons vos données de la manière suivante :</p>
            <ul class="list-disc pl-6 mb-4">
                <li>Votre adresse e-mail sera principalement utilisée pour la communication entre nos équipes et vous, notamment pour la récupération de mot de passe, les notifications d'événements et les mises à jour importantes.</li>
                {/* <li>Vos nom et prénom serviront exclusivement à vous identifier lors de nos interactions.</li> */}
                <li>Votre numéro de téléphone sera utilisé uniquement pour des communications directes et essentielles.</li>
                <li>Votre photo pourra être utilisée à des fins de communication liées à nos activités sauf avis contraire de votre part.</li>
            </ul>
            
            <p class="mb-4">Veuillez noter que l'utilisation de vos données peut évoluer, sans que vous en soyez informé de manière tacite.</p>
            
            <h3 class="text-lg font-semibold mb-2">Changements dans la Politique de Confidentialité</h3>
            <p class="mb-4">Nous pouvons mettre à jour cette politique de confidentialité pour refléter les changements dans nos pratiques de collecte et de gestion des données. Nous vous informerons de toute modification importante par le biais de notifications dans l'application.</p>
            
            <h3 class="text-lg font-semibold mb-2">Contact</h3>
            <p class="mb-4">Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à <b>support@epic-quiz.com</b>.</p>
            
            <p class="mb-4">En utilisant notre application, vous reconnaissez avoir pris connaissance de cette politique de confidentialité et y avoir consenti.</p>

            <h3 class="text-lg font-semibold mb-2">Sécurité des Données</h3>
            <p class="mb-4">Toutes les informations sont stockées de manière sécurisée dans une base de données à laquelle seuls les utilisateurs autorisés ont accès. Nous mettons en place des mesures de sécurité pour protéger vos données contre tout accès non autorisé, utilisation ou divulgation.</p>

        </>
    )
}