/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/solid'

const AlertSuccess = ({children, dismiss}) => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
              {children}
          </p>
        </div>
        {
            dismiss && (
                <div className="ml-auto pl-3" >
                    <div className="-mx-1.5 -my-1.5">
                        <button
                        type="button"
                        className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                        >
                        <span className="sr-only">Dismiss</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            )
        }
      </div>
    </div>
  )
}

const AlertDanger = ({children, dismiss}) => {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">
                {children}
            </p>
          </div>
          {
              dismiss && (
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                    <button
                        type="button"
                        className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                    >
                        <span className="sr-only">Dismiss</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    </div>
                </div>
              )
          }
        </div>
      </div>
    )
  }

export { AlertDanger, AlertSuccess};