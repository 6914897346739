export default function Rules() {
    return (
        <>
            <h2 class="text-2xl font-semibold mb-4">Règles du Jeu</h2>

            <h3 class="text-lg font-semibold mb-2">Une Partie :</h3>
            <ul class="list-disc pl-6 mb-4">
                <li>Une partie de jeu de quizz est identifiable par un nom et se déroule sur une période de temps prédéfinie.</li>
                <li>La durée de la partie est susceptible d'être modifiée par l'organisateur sans préavis.</li>
            </ul>

            <h3 class="text-lg font-semibold mb-2">Les Questions :</h3>
            <ul class="list-disc pl-6 mb-4">
                <li>Chaque participant reçoit un ensemble de questions tout au long de la partie. Le nombre de questions s'accumule progressivement pendant la partie.</li>
                <li>À chaque affichage d'une question, le nombre de questions autorisées diminue. Si votre compteur atteint zéro, vous devrez attendre qu'il soit réapprovisionné. Ce compteur ne peut pas être rétroactivement appliqué à un participant qui s'inscrit après le début de la partie.</li>
                <li>Chaque question est assortie d'un niveau de difficulté, identifiable par une icône lors de son affichage à l'écran. Les niveaux de difficulté incluent Bois, Pierre, Bronze, Argent et Or. Le niveau de difficulté affecte le temps imparti pour y répondre ainsi que le nombre de points attribués en cas de réponse correcte.</li>
                <li>Le niveau de difficulté d'une question peut être modifié sans préavis. L'échec à une question ou l'absence de réponse n'entraîne aucune perte de points.</li>
            </ul>

            <p class="mb-4">Ces règles visent à garantir un déroulement équitable et stimulant du jeu de quizz. Amusez-vous et que le meilleur gagne !</p>

        </>
    )
}