import React from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

import {AlertDanger, AlertSuccess} from './alerts'

const SERVER = process.env.NODE_ENV === 'production' ? 'https://app.epic-quiz.com' : 'http://localhost:8000'

export default function Contact() {
  const [success, setSuccess] = React.useState(false)
  const [fail, setFail] = React.useState(false)

  const handleSubmit = (event) => {
    const data = {
      email: event.target.email.value,
      sender: event.target.fullName.value,
      phone: event.target.phone.value,
      subject: event.target.subject.value,
      message: event.target.message.value,
      source: 'web'
    }

    setSuccess(false)
    setFail(false)

    fetch(`${SERVER}/api/users/contact-support/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
      .then((rep) => {
        document.getElementById("contact-form").reset();
        setSuccess(true)
      })
      .catch(() => {
        setFail(true)
      })

    event.preventDefault();
  }

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight sm:text-3xl text-left" style={{color: 171031}}>
              Contactez-nous
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500 text-left">
              Envie de partager vos commentaires ou de poser des questions ?
              N'hésitez pas à utiliser le formulaire à votre disposition ci-dessous ou à utiliser les informations de contact suivantes:
            </p>
            <dl className="mt-8 text-base text-gray-500 text-left">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>Halittar, 36 Rue Malard</p>
                  <p>31700 Blagnac - France</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">(+33) 06 954 621 59</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">(+225) 07 154 896 66</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">support@epic-quiz.com</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className='mb-4'>
            {success && <AlertSuccess>Votre message a été envoyé. Merci et à bientôt!</AlertSuccess>}
            {fail && <AlertDanger>Désolé, nous rencontrons des problèmes actuellement. Veuillez réessayer plus tard!</AlertDanger>}
          </div>
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form className="grid grid-cols-1 gap-y-6" onSubmit={handleSubmit} id="contact-form">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Nom & Prenoms
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Nom & Prenoms"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                Contact
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Contact"
                  required
                />
              </div>
              <div>
                <label htmlFor="subject" className="sr-only">
                Sujet
                </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Sujet"
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  placeholder="Message"
                  required
                  defaultValue={''}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={styles.primaryColor}
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}


const styles = {
  primaryColor: {
    backgroundColor: '#171031'
  },
}