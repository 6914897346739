export default function Purpose() {
    return (
        <>
            <h2 class="text-2xl font-semibold mb-4">But du jeu</h2>
            <p class="mb-2">Dominez tous les autres en répondant avec brio aux questions posées. Seuls les plus brillants, les plus rusés, et les plus rapides atteindront le sommet du classement à la fin de chaque partie, et ils seront grandement récompensés.</p>
            <p class="mb-4">Le prix affiché, le trésor ultime, reviendra au champion incontesté, à celui qui règnera en première place. Oserez-vous relever le défi et devenir le roi des quiz ?</p>
            <p class="mb-4">
                <b>NB</b>: Ni Google ou Apple ne sont sponsors ou impliqués de quelque manière que ce soit dans ce jeu.
            </p>
        </>
    )
}