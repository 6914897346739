import NavBar from '../components/navbar';
import DataPolicy from '../components/dataPoliticy';
import Purpose from '../components/purpose';
import Rules from '../components/rules';
import DeleteAccount from '../components/delAccount';

function Privacy() {
  return (
    <div>
      <NavBar />
      <div class="bg-white p-6 rounded-lg shadow-lg mx-auto max-w-7xl">
        <div class='mb-8'>
          <h1 class="text-4xl font-semibold mb-2">Règlement et conditions du jeu</h1>
          <p class="text-sm text-gray-600">Dernière mise à jour : 20/02/2024</p>
        </div>
        <Purpose />
        <Rules />
        <DataPolicy />
        <DeleteAccount />
      </div>
    </div>
  )
/*     return (
      <div dangerouslySetInnerHTML={{__html: htmlContent}} />
    ) */
}

const styles = {
  bgColor: {
    backgroundColor: '#171031'
  }
}

export default Privacy;