
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import View1 from '../media/screenshots/view1.png'
import View2 from '../media/screenshots/view2.png'
import View3 from '../media/screenshots/view3.png'
import View4 from '../media/screenshots/view4.png'
import View5 from '../media/screenshots/view5.png'
import View6 from '../media/screenshots/view6.png'
import View7 from '../media/screenshots/view7.png'
import View8 from '../media/screenshots/view8.png'
import AndroidBadge from '../media/badges/android.svg'
import IosBadge from '../media/badges/ios.svg'


export default function Hero() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  };
  return (
    <div className="relative overflow-hidden" style={styles.bgColor}>
      <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width={640}
          height={784}
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x={118}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect y={72} width={640} height={640} className="text-gray-50" />
          <rect x={118} width={404} height={784} fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
        </svg>
      </div>

      <div className="relative pt-2">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="block mb-1" style={styles.playTextColor}>Jouez,</span>
                  <span className="block mb-1" style={styles.learnTextColor}>Apprenez,</span>
                  <span className="block mb-1" style={styles.winTextColor}>Gagnez</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Amusez-vous tout en élargissant votre culture générale grâce à notre jeu de quizz.
              Acceptez notre défi et remportez de superbes récompenses !
              Alors, êtes-vous prêt à relever le défi ?

              </p>
              <p class="mt-8 text-sm text-gray-300 uppercase tracking-wide font-semibold sm:mt-10">
                Télécharge notre application mobile
              </p>
              <div class="mt-2 w-full sm:mx-auto sm:max-w-lg lg:-ml-2">
                <div class="inline-flex space-x-2">
                  <div class="flex-1">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.halittar.epicquiz" rel="noreferrer">
                      <img src={AndroidBadge} alt="android" style={{height: '80px'}} />
                    </a>
                  </div>
                  <div class="flex-1">
                    <a target="_blank" href="https://apps.apple.com/ci/app/epic-quiz/id6476051075?platform=iphone" rel="noreferrer">
                      <img src={IosBadge} alt="IOS" style={{height: '50px', marginTop: 14}}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
                <rect x={118} width={404} height={784} fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md lg:-top-20 lg:left-10">
                <Slider {...settings}>
                  <div><img src={View1} alt="" /></div>
                  <div><img src={View2} alt="" /></div>
                  <div><img src={View3} alt="" /></div>
                  <div><img src={View4} alt="" /></div>
                  <div><img src={View5} alt="" /></div>
                  <div><img src={View6} alt="" /></div>
                  <div><img src={View7} alt="" /></div>
                  <div><img src={View8} alt="" /></div>
                </Slider>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}


const styles = {
  bgColor: {
    backgroundColor: '#171031'
  },
  playTextColor: {
    color: '#B30056'
  },
  learnTextColor: {
    color: '#F5FF43'
  },
  winTextColor: {
    color: '#00C79F'
  }
}