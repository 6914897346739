import './App.css';
import NavBar from './components/navbar';
import Hero from './components/hero';
// import Features from './components/feature';
// import Testimonial from './components/testimonials';
import Contact from './components/contact';
import Footer from './components/footer';
import DownloadPopup from './components/downloadPopup'

function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      {/* <Features />
      <Testimonial /> */}
      <Contact />
      <Footer />
      <DownloadPopup />
    </div>
  );
}

export default App;

