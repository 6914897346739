import ReactDOM from 'react-dom/client';
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";

import './index.css';
import App from './App';
import Privacy from './pages/privacy';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <HashRouter>
    <Routes>
      <Route path='/' element={<App />}/>
      <Route path='privacy' element={<Privacy />} />
    </Routes>
  </HashRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
